import {TotalListeningReportDTO} from "./TotalListeningReportDTO";

export class TotalListeningReportForAccountGroupDTO extends TotalListeningReportDTO{
    accountGroupName: string;
    accountGroupId: number;
    dateCreated: string;
    totalAccountNumber: number;

    constructor(json: any) {
        super(json);
        this.accountGroupName = json.accountGroupName;
        this.accountGroupId = json.accountGroupId;
        this.dateCreated = json.dateCreated;
        this.totalAccountNumber = json.totalAccountNumber;
    }
}
