import {AppBarPage} from "../../components/AppBarPage";
import AppBar from "@mui/material/AppBar";
import * as React from "react";
import {
    Button,
    FormControl, Grid,
    Paper,
    Select, SelectChangeEvent,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TablePagination, TableRow, TextField, Toolbar
} from "@mui/material";
import {useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {AnalyticsService} from "../../services/AnalyticsService";
import dayjs from "dayjs";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {ServicesService} from "../../services/ServicesService";
import {ServiceDTO} from "../../models/ServiceDTO";
import {strings} from "../../localization/Localization";
import Box from "@mui/material/Box";
import "../../App.css"
import {FullPageLoadingCircle} from "../../components/FullPageLoadingCircle";
import {Routes} from "../../router/Routes";
import {useNavigate} from "react-router-dom";
import {TotalListeningReportDTO} from "../../models/TotalListeningReportDTO";
import {TotalListeningReportForAccountGroupDTO} from "../../models/TotalListeningReportForAccountGroupDTO";
import {ViewsCount} from "../../models/ViewsCount";
import {ChartComponent} from "../../components/ChartComponent";
import {defaultRowsPerPageOptions} from "../../common/Constants";
import {PaginationPlus} from "../../components/PaginationPlus";
import {StorageService} from "../../storage/StorageService";

export function SummaryReportPage() {
    const id = StorageService.getJWTPayload()?.clientId;
    const [serviceIdFilter, setServiceIdFilter] = useState<number | undefined>(undefined);
    const [dateFrom, setDateFrom] = useState<string>('');
    const [dateTo, setDateTo] = useState<string>('');
    const [dailyListeningReports, setDailyListeningReports] = useState<TotalListeningReportDTO[]>([]);
    const [dailyListeningReportsForAccountGroup, setDailyListeningReportsForAccountGroup] = useState<TotalListeningReportForAccountGroupDTO[]>([]);
    const [services, setServices] = useState<ServiceDTO[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [serviceIdForAccountGroupFilter, setServiceIdForAccountGroupFilter] = useState<number | undefined>(undefined);
    const [dateForAccountGroup, setDateForAccountGroup] = useState<string>('');
    const [totalElements, setTotalElements] = useState(0);
    const [totalElementsForAccountGroup, setTotalElementsForAccountGroup] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [rowsPerPageForAccountGroup, setRowsPerPageForAccountGroup] = useState(10);
    const [pageForAccountGroup, setPageForAccountGroup] = useState( 0);

    const navigate = useNavigate();

    const handleDashboardClick = () => {
        navigate(Routes.HOME);
    };

    const handleServiceFilter = (event: SelectChangeEvent) => {
        setServiceIdFilter(+event.target.value);
        setPage(0);
    }

    const handleDateFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDateFrom = event.target.value;
        const selectedDateTo = dayjs(dateTo);

        if (dayjs(selectedDateFrom).isAfter(selectedDateTo)) {
            alert('Date from cannot be later than date to');
        } else {
            const maxDateFrom = selectedDateTo.subtract(3, 'month');

            if (dayjs(selectedDateFrom).isBefore(maxDateFrom)) {
                alert('Date from cannot be more than 3 months from date to');
            } else {
                setDateFrom(selectedDateFrom);
            }
        }
        setPage(0);
    };

    const handleDateToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDateTo = event.target.value;
        const selectedDateFrom = dayjs(dateFrom);

        if (dayjs(selectedDateTo).isBefore(selectedDateFrom)) {
            alert('Date to cannot be earlier than date from');
        } else {
            const maxDateTo = selectedDateFrom.add(3, 'month');

            if (dayjs(selectedDateTo).isAfter(maxDateTo)) {
                alert('Date to cannot be more than 3 months from date from');
            } else {
                setDateTo(selectedDateTo);
            }
        }
        setPage(0);
    };

    const handleServiceForAccountGroupFilter = (event: SelectChangeEvent) => {
        setServiceIdForAccountGroupFilter(+event.target.value);
        setPage(0);

    }

    const handleDateForAccountGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDateForAccountGroup(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        async function getAllServices() {
            const services = await ServicesService.getAllServices();
            setServices(services);
        }

        getAllServices()
    }, []);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            const dailyReports = await AnalyticsService.findDailyListeningReports(
                id,
                DateTimeUtils.formatDateDayJs(dayjs(dateFrom)),
                DateTimeUtils.formatDateDayJs(dayjs(dateTo)),
                serviceIdFilter
            );

            setDailyListeningReports(dailyReports);
            setTotalElements(dailyReports.length);
            setIsLoading(false);
        }

        fetchData();
    }, [dateFrom, dateTo, serviceIdFilter]);

    useEffect(() => {
        async function fetchDataForAccountGroup() {
            setIsLoading(true);

            const dailyReportsForAccountGroup = await AnalyticsService.findDailyListeningReportsForAccountGroup(
                id,
                DateTimeUtils.formatDateDayJs(dayjs(dateForAccountGroup)),
                serviceIdForAccountGroupFilter
            );

            setDailyListeningReportsForAccountGroup(dailyReportsForAccountGroup);
            setTotalElementsForAccountGroup(dailyReportsForAccountGroup.length);

            setIsLoading(false);
        }

        fetchDataForAccountGroup();
    }, [dateForAccountGroup, serviceIdForAccountGroupFilter]);
    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    function handleChangePageForAccountGroup(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPageForAccountGroup(newPage);
    }

    function handleChangeRowsPerPageForAccountGroup(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPageForAccountGroup(parseInt(event.target.value, 10));
        setPageForAccountGroup(0);
    }

    const groupReportsByType = (reports: TotalListeningReportDTO[]) =>
        reports.reduce((acc, report) => {
            (acc[report.type] ||= []).push(report);
            return acc;
        }, {} as Record<string, TotalListeningReportDTO[]>);

    const formatChartData = (groupedReports: Record<string, TotalListeningReportDTO[]>, dateFrom?: string, dateTo?: string) => {
        if (!dateFrom || !dateTo) return [];

        const fromDate = dayjs(dateFrom);
        const toDate = dayjs(dateTo);
        const allDates = Array.from(
            { length: toDate.diff(fromDate, 'day') + 1 },
            (_, i) => fromDate.add(i, 'day').format('YYYY-MM-DD')
        );

        return Object.entries(groupedReports).map(([type, reports]) => ({
            name: type,
            data: allDates.map(date => ({
                x: date,
                y: reports.find(report => report.startDate === date)?.listeningCount || 0,
            })),
        }));
    };

    const tickAmountChange = (dateFrom?: string, dateTo?: string) => {
        if (!dateFrom || !dateTo) return 0;

        const daysDifference = dayjs(dateTo).diff(dayjs(dateFrom), 'day');
        return daysDifference <= 30 ? daysDifference + 1 : 29;
    };

    const groupedReports = groupReportsByType(dailyListeningReports);
    const chartData = formatChartData(groupedReports, dateFrom, dateTo);
    const categories = [...new Set(dailyListeningReports.map(report => report.startDate))];
    const views = dailyListeningReports
        .filter(report => dayjs(report.startDate).isAfter(dayjs(dateFrom).subtract(1, 'day')) && dayjs(report.startDate).isBefore(dayjs(dateTo).add(1, 'day')))
        .reduce((acc, report) => acc + report.listeningCount, 0);

    const formatBarChartData = (reports: TotalListeningReportForAccountGroupDTO[], chosenDate: string) => { const filteredReports = reports.filter(report => report.startDate === chosenDate);
        const groupedByDate = filteredReports.reduce((acc, report) => {
            if (!acc[report.startDate]) {
                acc[report.startDate] = {};
            }
            if (!acc[report.startDate][report.accountGroupName]) {
                acc[report.startDate][report.accountGroupName] = 0;
            }
            acc[report.startDate][report.accountGroupName] += report.listeningCount;
            return acc;
        }, {} as Record<string, Record<string, number>>);

        const categories = Object.keys(groupedByDate);
        const accountGroups = Array.from(new Set(filteredReports.map(report => report.accountGroupName)));

        const series = accountGroups.map(accountGroupName => ({
            name: accountGroupName,
            data: categories.map(date => groupedByDate[date][accountGroupName] || 0)
        }));

        return { categories, series };
    };

    const barChartData = formatBarChartData(dailyListeningReportsForAccountGroup, dateForAccountGroup);
    const { categories: barChartCategories, series: barChartSeries } = barChartData;
    const barChartViews = dailyListeningReportsForAccountGroup
        .filter(report => report.startDate === dateForAccountGroup)
        .reduce((acc, report) => acc + report.listeningCount, 0);

    const paginatedReports = dailyListeningReports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const paginatedReportsForAccountGroup = dailyListeningReportsForAccountGroup.slice(pageForAccountGroup * rowsPerPageForAccountGroup, pageForAccountGroup * rowsPerPageForAccountGroup + rowsPerPageForAccountGroup);

    return (
        <>
            <AppBarPage pageTitle={strings.analytics} component={
                <>
                    <FullPageLoadingCircle
                        loading={isLoading}
                    />
                    <AppBar position="absolute" sx={{marginTop: "65.5px", minHeight: "65.5px"}} color={"secondary"}>
                        <Toolbar>
                            <Button
                                onClick={handleDashboardClick}
                                sx={{
                                    marginLeft: "20px",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    color: "black",
                                    backgroundColor: "#efefef",
                                    width: "10%",
                                    '&:hover': {
                                        backgroundColor: "lightgray",
                                    },
                                }}
                            >
                                {strings.dashboard}
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Box>
                        <Typography style={{
                            fontSize: "2rem",
                            fontWeight: "bold",
                            marginTop: "108px",
                            marginBottom: "70px"
                        }}>{strings.byServiceHeader}</Typography>

                        <div className={"mainDiv"} style={{
                            flexDirection: "row",
                            marginLeft: "40px",
                            width: "77.5%",
                            justifyContent: "space-between",
                            marginTop: "25px",
                            marginBottom: "25px"
                        }}>
                            <div className="div1" style={{display: "flex", flexDirection: "row"}}>
                                <FormControl className="formControl"
                                             style={{float: "right", display: "flex", flexDirection: "row"}}
                                             variant="outlined" fullWidth>
                                    <Typography style={{
                                        marginRight: "20px",
                                        fontSize: "18px",
                                        marginTop: "10px"
                                    }}>{strings.serviceFilter}</Typography>
                                    <Select
                                        labelId="serviceFilter"
                                        className="dropdown-menu"
                                        value={serviceIdFilter?.toString() ?? ""}
                                        onChange={handleServiceFilter}
                                        style={{
                                            border: "black",
                                            borderStyle: "solid",
                                            borderWidth: "1.5px",
                                            borderRadius: "1px",
                                            backgroundColor: "White",
                                            width: "150px"
                                        }}
                                    >
                                        {services?.map((filter) => (
                                            <MenuItem disableRipple key={filter.id} value={filter.id}>
                                                {filter.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="div2" style={{display: "flex", flexDirection: "row"}}>
                                <FormControl className="formControl" variant="outlined"
                                             style={{float: "right", display: "flex", flexDirection: "row"}} fullWidth>
                                    <Typography style={{
                                        marginRight: "20px",
                                        fontSize: "18px",
                                        marginTop: "10px"
                                    }}>{strings.dateFrom}</Typography>
                                    <TextField
                                        type="date"
                                        value={dateFrom}
                                        onChange={handleDateFromChange}
                                        InputLabelProps={{shrink: true}}
                                        style={{
                                            border: "black",
                                            borderStyle: "solid",
                                            borderWidth: "1.5px",
                                            borderRadius: "1px",
                                            backgroundColor: "White"
                                        }}
                                    />
                                </FormControl>
                            </div>

                            <div className="div3" style={{display: "flex", flexDirection: "row"}}>
                                <FormControl className="formControl" variant="outlined"
                                             style={{float: "right", display: "flex", flexDirection: "row"}} fullWidth>
                                    <Typography style={{
                                        marginRight: "20px",
                                        fontSize: "18px",
                                        marginTop: "10px"
                                    }}>{strings.dateTo}</Typography>
                                    <TextField
                                        type="date"
                                        value={dateTo}
                                        onChange={handleDateToChange}
                                        InputLabelProps={{shrink: true}}
                                        style={{
                                            border: "black",
                                            borderStyle: "solid",
                                            borderWidth: "1.5px",
                                            borderRadius: "1px",
                                            backgroundColor: "White"
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <TableContainer component={Paper}
                                        style={{marginTop: "70px", borderRadius: "10px", marginBottom: "70px"}}>
                            <Table>
                                <TableHead>
                                    <TableRow></TableRow>
                                    <TableRow>
                                        <TableCell width={"25%"}
                                                   style={{fontFamily: "sans-serif"}}>
                                            {strings.startDate}
                                        </TableCell>

                                        <TableCell width={"25%"}
                                                   style={{fontFamily: "sans-serif"}}>
                                            {strings.service}
                                        </TableCell>

                                        <TableCell width={"25%"}
                                                   style={{fontFamily: "sans-serif"}}>
                                            {strings.totalStreams}
                                        </TableCell>

                                        <TableCell width={"25%"}
                                                   style={{fontFamily: "sans-serif"}}>
                                            {strings.numberOfListeningAccounts}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {paginatedReports.map((dataItem: TotalListeningReportDTO) => (
                                        <TableRow key={`${dataItem.startDate}-${dataItem.type}`} hover={true}>
                                            <TableCell
                                                style={{fontFamily: "sans-serif"}}>{dataItem.startDate}</TableCell>

                                            <TableCell style={{fontFamily: "sans-serif"}}>{dataItem.type}</TableCell>

                                            <TableCell
                                                style={{fontFamily: "sans-serif"}}>{dataItem.listeningCount}</TableCell>

                                            <TableCell
                                                style={{fontFamily: "sans-serif"}}>{dataItem.accountNumber}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px" }}>
                                <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)} />
                                <TablePagination
                                    rowsPerPageOptions={defaultRowsPerPageOptions}
                                    component="div"
                                    count={totalElements}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </TableContainer>

                        <ChartComponent
                            series={chartData}
                            views={views}
                            streamsFilter={ViewsCount.STAT_STREAMS}
                            text={strings.areaPlotHeader}
                            categories={categories}
                            tickAmount={tickAmountChange(dateFrom, dateTo)}
                            xaxisType="datetime"
                        />
                    </Box>
                    <Box>
                        <hr style={{marginTop: "70px"}}/>
                        <Typography style={{
                            fontSize: "2rem",
                            fontWeight: "bold",
                            marginTop: "70px",
                            marginBottom: "70px"
                        }}>{strings.byAccountGroupHeader}</Typography>
                        <div className={"mainDiv"} style={{
                            flexDirection: "row",
                            marginLeft: "40px",
                            width: "77.5%",
                            justifyContent: "space-between",
                            marginTop: "25px",
                            marginBottom: "25px"
                        }}>
                            <div className="div1" style={{display: "flex", flexDirection: "row"}}>
                                <FormControl className="formControl"
                                             style={{float: "right", display: "flex", flexDirection: "row"}}
                                             variant="outlined" fullWidth>
                                    <Typography style={{
                                        marginRight: "20px",
                                        fontSize: "18px",
                                        marginTop: "10px"
                                    }}>{strings.serviceFilter}</Typography>
                                    <Select
                                        labelId="serviceFilter"
                                        className="dropdown-menu"
                                        value={serviceIdForAccountGroupFilter?.toString() ?? ""}
                                        onChange={handleServiceForAccountGroupFilter}
                                        style={{
                                            border: "black",
                                            borderStyle: "solid",
                                            borderWidth: "1.5px",
                                            borderRadius: "1px",
                                            backgroundColor: "White",
                                            width: "150px"
                                        }}
                                    >
                                        {services?.map((filter) => (
                                            <MenuItem disableRipple key={filter.id} value={filter.id}>
                                                {filter.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="div2" style={{display: "flex", flexDirection: "row"}}>
                                <FormControl className="formControl" variant="outlined"
                                             style={{float: "right", display: "flex", flexDirection: "row"}} fullWidth>
                                    <Typography style={{
                                        marginRight: "20px",
                                        fontSize: "18px",
                                        marginTop: "10px"
                                    }}>{strings.date}</Typography>
                                    <TextField
                                        type="date"
                                        value={dateForAccountGroup || ''}
                                        onChange={handleDateForAccountGroupChange}
                                        InputLabelProps={{shrink: true}}
                                        style={{
                                            border: "black",
                                            borderStyle: "solid",
                                            borderWidth: "1.5px",
                                            borderRadius: "1px",
                                            backgroundColor: "White"
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <TableContainer component={Paper}
                                        style={{marginTop: "70px", borderRadius: "10px", marginBottom: "70px"}}>
                            <Table>
                                <TableHead>
                                    <TableRow></TableRow>
                                    <TableRow>
                                        <TableCell width={"12.5%"}
                                                   style={{fontFamily: "sans-serif"}}>
                                            {strings.startDate}
                                        </TableCell>

                                        <TableCell width={"12.5%"}
                                                   style={{fontFamily: "sans-serif"}}>
                                            {strings.service}
                                        </TableCell>

                                        <TableCell width={"12.5%"}
                                                   style={{fontFamily: "sans-serif"}}>
                                            {strings.totalStreams}
                                        </TableCell>

                                        <TableCell width={"12.5%"}
                                                   style={{fontFamily: "sans-serif"}}>
                                            {strings.numberOfListeningAccounts}
                                        </TableCell>

                                        <TableCell width={"12.5%"}
                                                   style={{fontFamily: "sans-serif"}}>
                                            {strings.totalAccountsInGroup}
                                        </TableCell>

                                        <TableCell width={"12.5%"}
                                                   style={{fontFamily: "sans-serif"}}>
                                            {strings.accountGroupName}
                                        </TableCell>

                                        <TableCell width={"12.5%"}
                                                   style={{fontFamily: "sans-serif"}}>
                                            {strings.accountGroupId}
                                        </TableCell>

                                        <TableCell width={"12.5%"}
                                                   style={{fontFamily: "sans-serif"}}>
                                            {strings.dateCreated}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {paginatedReportsForAccountGroup.map((dataItem: TotalListeningReportForAccountGroupDTO) => (
                                        <TableRow key={`${dataItem.startDate}-${dataItem.type}-${dataItem.accountGroupName}-${dataItem.accountGroupId}-${dataItem.dateCreated}`} hover={true}>
                                            <TableCell
                                                style={{fontFamily: "sans-serif"}}>{dataItem.startDate}</TableCell>

                                            <TableCell style={{fontFamily: "sans-serif"}}>{dataItem.type}</TableCell>

                                            <TableCell
                                                style={{fontFamily: "sans-serif"}}>{dataItem.listeningCount}</TableCell>

                                            <TableCell
                                                style={{fontFamily: "sans-serif"}}>{dataItem.accountNumber}</TableCell>

                                            <TableCell
                                                style={{fontFamily: "sans-serif"}}>{dataItem.totalAccountNumber}</TableCell>

                                            <TableCell
                                                style={{fontFamily: "sans-serif"}}>{dataItem.accountGroupName}</TableCell>

                                            <TableCell
                                                style={{fontFamily: "sans-serif"}}>{dataItem.accountGroupId}</TableCell>

                                            <TableCell
                                                style={{fontFamily: "sans-serif"}}>{dataItem.dateCreated}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px" }}>
                                <PaginationPlus totalElements={totalElementsForAccountGroup} rowsPerPage={rowsPerPageForAccountGroup} page={pageForAccountGroup} onChangePage={(page) => handleChangePageForAccountGroup(null, page)} />
                                <TablePagination
                                    rowsPerPageOptions={defaultRowsPerPageOptions}
                                    component="div"
                                    count={totalElementsForAccountGroup}
                                    rowsPerPage={rowsPerPageForAccountGroup}
                                    page={pageForAccountGroup}
                                    onPageChange={handleChangePageForAccountGroup}
                                    onRowsPerPageChange={handleChangeRowsPerPageForAccountGroup}
                                />
                            </Grid>
                        </TableContainer>
                        <ChartComponent
                            series={barChartSeries}
                            views={barChartViews}
                            streamsFilter={ViewsCount.STAT_STREAMS}
                            text={strings.barChartHeader}
                            categories={barChartCategories}
                            tickAmount={barChartCategories?.length}
                            type="bar"
                            horizontal={true}
                            showDataLabels={true}
                        />
                    </Box>
                </>
            }/>
        </>
    )
}
